export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button',

  CURRENT_SEARCH: 'Search Filters',
  START_OVER: 'Remove All',
  FACET_TITLE_ICON: `
    <rt-virtual rt-if="this.isCollapsed" template="icons/facet-plus" />
    <rt-virtual rt-if="!this.isCollapsed" template="icons/facet-minus" />`,
  MAX_PRICE: 'Max',
  MIN_PRICE: 'Min',

  FILTERS_ICON: '<rt-virtual template="icons/filter-button" />',

  GO_TEXT: 'FIND PARTS',
  SELECT_YOUR_VEHICLE: 'SEARCH FOR PARTS',

  FITMENT_TABLE_VEHICLE_SPECIFIC:
    '<p>Complete the Check It Fits tool and confirm all information in the Fitment Details section above to ensure you select the right part for your application.</p>',
  FITMENT_TABLE_MANY_FITMENTS: `
    <p>Complete the Check It Fits tool and confirm all information in the Fitment Details section above to ensure you select the right part for your application.</p>
    <p>Currently displaying 1000 fitments, yet the product has additional fitments.</p>`,

  SEARCH_BOX_FORM_CLASS: 'form-fieldset',
  SEARCH_BOX_PLACEHOLDER: 'Part/OEM/Competitor # Search',
  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'search-button',
};
