
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function oeNumbersRT () {
    function scopeBrandNamePartNumbers1(oeInfo, oe, oeIndex) {
        var brandName = oe[0];
        var partNumbers = oe[1];
        return _createElement('div', {
            'className': 'productView-table-row',
            'key': brandName
        }, _createElement('div', { 'className': 'productView-table-header' }, brandName), _createElement('div', { 'className': 'productView-table-data' }, partNumbers));
    }
    function repeatOe2(oeInfo, oe, oeIndex, brandName, partNumbers) {
        return scopeBrandNamePartNumbers1.apply(this, [
            oeInfo,
            oe,
            oeIndex
        ]);
    }
    function scopeOeInfo3() {
        var oeInfo = Object.entries(JSON.parse(this.productData[0].value));
        return _createElement.apply(this, [
            'div',
            { 'key': '0' },
            _createElement('h2', { 'className': 'productView-title' }, 'OE Numbers'),
            _map(oeInfo, repeatOe2.bind(this, oeInfo))
        ]);
    }
    return this.productData.length ? scopeOeInfo3.apply(this, []) : null;
}
        export const componentNames = []