
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function requestPanelRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                return _createElement('div', { 'className': 'cm_vehicle-widget_select' }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, _createElement.apply(this, [
                    'select',
                    {
                        'className': 'cm_select_inner-select cm_select__pretty',
                        'onChange': this.onChange,
                        'value': this.firstSelectedTerm || '',
                        'disabled': this.disabled
                    },
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    _map(this.entries, repeatEntry1.bind(this))
                ])));
            }, { count: undefined })];
    }
    function repeatSelects2(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                return _createElement('div', { 'className': 'cm_vehicle-widget_select' }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, _createElement.apply(this, [
                    'select',
                    {
                        'className': 'cm_select_inner-select cm_select__pretty',
                        'onChange': this.onChange,
                        'value': this.firstSelectedTerm || '',
                        'disabled': this.disabled
                    },
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    _map(this.entries, repeatEntry1.bind(this))
                ])));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__home cm_vehicle-widget__header cm_vehicle-widget__collapsible' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns cm_mobile-hide' }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, 'SEARCH FOR PARTS'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary',
        'onClick': this.submit,
        'disabled': !this.hasFullSelection
    }, '\n    FIND PARTS\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button',
        'onClick': () => {
            window.Convermax.discardVehicle();
            this.clear();
        },
        'disabled': this.disableClear
    }, '\n    Clear\n  ')))), _createElement('div', {
        'className': 'cm_vehicle-widget_header cm_desktop-hide',
        'onClick': this.toggleCollapsed
    }, !this.collapsed ? _createElement('span', { 'key': '2338' }, '-') : null, this.collapsed ? _createElement('span', { 'key': '2381' }, '+') : null, _createElement('span', { 'className': 'cm_vehicle-widget_header-label' }, '\n      SEARCH FOR PARTS\n    ')), _createElement('div', { 'className': 'cm_collapsible-elem cm_desktop-hide' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects2.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary',
        'onClick': this.submit,
        'disabled': !this.hasFullSelection
    }, '\n    FIND PARTS\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button',
        'onClick': () => {
            window.Convermax.discardVehicle();
            this.clear();
        },
        'disabled': this.disableClear
    }, '\n    Clear\n  '))))));
}
        export const componentNames = []