
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatSplittedQualifier1(qualifier, qualifierIndex, splittedQualifier, splittedQualifierIndex) {
                    return _createElement('ul', {
                        'className': 'card-text--asm',
                        'key': splittedQualifier
                    }, _createElement('li', {}, splittedQualifier));
                }
                function repeatQualifier2(qualifier, qualifierIndex) {
                    return _createElement.apply(this, [
                        'div',
                        {},
                        _map(qualifier.split('\n'), repeatSplittedQualifier1.bind(this, qualifier, qualifierIndex))
                    ]);
                }
                function repeatSplittedNote3(note, noteIndex, splittedNote, splittedNoteIndex) {
                    return _createElement('ul', {
                        'className': 'card-text--asm',
                        'key': splittedNote
                    }, _createElement('li', {}, splittedNote));
                }
                function repeatNote4(note, noteIndex) {
                    return _createElement.apply(this, [
                        'div',
                        {},
                        _map(note.split('\n'), repeatSplittedNote3.bind(this, note, noteIndex))
                    ]);
                }
                function repeatFitment5(fitment, fitmentIndex) {
                    return _createElement('li', { 'key': fitment }, fitment);
                }
                return _createElement('div', { 'className': 'productCarousel-slide' }, _createElement('article', { 'className': 'card' }, _createElement('figure', { 'className': 'card-figure' }, _createElement('a', {
                    'className': 'card-figure-link',
                    'href': this.getVehicleSearchUrl(this.custom_url)
                }, _createElement('div', { 'className': 'card-img-container' }, _createElement('img', {
                    'src': this.imageOrDefault(this.images_image_thumbnail1 || this.image_standard),
                    'alt': this.removeHTML(this.name),
                    'onError': this.onImageError,
                    'className': 'card-image lazyautosizes lazyloaded'
                }))), _createElement('div', { 'className': 'DesktopDisplay' }, window.Convermax.isLoggedIn ? _createElement('div', {
                    'className': 'card-section card-section--availability card-section--show card-section--show',
                    'key': '558'
                }, this?.inventory_level > 0 ? _createElement('div', {
                    'className': 'card-stock card-stock--inStock',
                    'key': '702'
                }, '\n                  ', this?.inventory_level >= 50 ? '50+' : this?.inventory_level, ' In Stock\n                ') : null, this?.inventory_level == 0 ? _createElement('div', {
                    'className': 'card-stock card-stock--outStock',
                    'key': '925'
                }, '\n                  Out Of Stock\n                ') : null, _createElement('div', {
                    'className': 'card-qtyAdded',
                    'data-qtyincart': this.id
                })) : null)), _createElement('div', { 'className': 'card-section card-section--body' }, _createElement('div', { 'className': 'ColWrapper' }, _createElement('div', { 'className': 'col1' }, _createElement('p', {
                    'className': 'card-text card-text--brand productView-brand NoMargin',
                    'data-test-info-type': 'brandName'
                }, _createElement('strong', {}, this.removeHTML(this.brand_name))), _createElement('h4', { 'className': 'card-title NoMargin' }, _createElement('a', Object.assign({}, { 'href': this.getVehicleSearchUrl(this.custom_url) }, { dangerouslySetInnerHTML: { __html: this.name } }))), this.sku ? _createElement('p', {
                    'className': 'card-text card-text--brand',
                    'product-data-sku': this.removeHTML(this.sku),
                    'key': '1744'
                }, _createElement('strong', {}, 'Part Number:'), ' ', this.removeHTML(this.sku), '\n                ') : null, this.Product_Condition ? _createElement('p', {
                    'className': 'card-text card-text--brand',
                    'key': '1960'
                }, _createElement('strong', {}, 'Product Condition: '), this.Product_Condition, '\n                ') : null, this.Grade_Type ? _createElement('p', {
                    'className': 'card-text card-text--brand',
                    'key': '2155'
                }, _createElement('strong', {}, 'Grade Type: '), this.Grade_Type, '\n                ') : null, this.Custom_Type ? _createElement('p', {
                    'className': 'card-text card-text--brand',
                    'key': '2329'
                }, _createElement('strong', {}, 'Type: '), this.Custom_Type, '\n                ') : null, this.Position ? _createElement('p', {
                    'className': 'card-text card-text--brand',
                    'key': '2499'
                }, _createElement('strong', {}, 'Position: '), this.Position, '\n                ') : null), _createElement('div', { 'className': 'col2' }, window.Convermax.isLoggedIn && this.Lifecycle_Status === 'Available to Order' ? [
                    this.on_sale ? _createElement('div', {
                        'className': 'price-section WasPrice',
                        'key': '27211'
                    }, _createElement('div', { 'className': 'price-section--label price--sale-label' }, 'Was:'), _createElement('div', { 'className': 'price-section--wrapper' }, _createElement('div', { 'className': 'price-section--withoutTax non-sale-price--withoutTax' }, _createElement('span', {
                        'data-product-non-sale-price-without-tax': true,
                        'className': 'price price--non-sale'
                    }, '\n                          ', this.formatPrice(this.regular_price), '\n                        ')))) : null,
                    _createElement('div', {
                        'className': 'price-section MainPrice',
                        'key': '27213'
                    }, this.on_sale ? _createElement('div', {
                        'className': 'price-section--label',
                        'key': '3502'
                    }, _createElement('span', { 'className': 'price-label' }, ' Price '), _createElement('span', { 'className': 'price-now-label' }, ' Now ')) : null, _createElement('div', { 'className': 'price-section--wrapper' }, _createElement('div', { 'className': 'price-section--withoutTax price-section--primary' }, _createElement('span', {
                        'data-product-price-without-tax': true,
                        'className': 'price price--withoutTax price-primary'
                    }, this.formatPrice(this.price))), _createElement('div', {
                        'id': 'addon-' + this.removeHTML(this.sku),
                        'data-addon': true
                    }), _createElement('div', { 'className': 'price-section CoreChargeText' }, _createElement('div', {
                        'className': 'CoreMessage',
                        'id': 'CoreChargePrice-' + this.removeHTML(this.sku)
                    }), _createElement('div', {
                        'className': 'PDPCoreIcon',
                        'id': 'PDPCoreIcon-' + this.removeHTML(this.sku)
                    })), _createElement('div', { 'className': 'price-section CoreChargeText' }, _createElement('div', {
                        'className': 'FPSMessage',
                        'id': 'FPSPrice-' + this.removeHTML(this.sku)
                    }), _createElement('div', {
                        'className': 'PDPFPSIcon',
                        'id': 'PDPFPSIcon-' + this.removeHTML(this.sku)
                    }))))
                ] : null)), this.QualifierText?.length || this.Notes?.length || !window.Convermax.isVehicleSelected() && this.fitment_summary?.length ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'card-attributes',
                        'key': '4819'
                    },
                    _map(this.QualifierText, repeatQualifier2.bind(this)),
                    _map(this.Notes, repeatNote4.bind(this)),
                    !window.Convermax.isVehicleSelected() ? _createElement('div', {
                        'className': 'card-text--asm',
                        'key': '5552'
                    }, _createElement('button', {
                        'onClick': e => window.Convermax.showCardFitments(e),
                        'className': 'card-fitments-button button button--primary'
                    }, '\n                  Show fitments\n                '), _createElement.apply(this, [
                        'ul',
                        { 'className': 'card-fitments cm_hide' },
                        _map(this.fitment_summary, repeatFitment5.bind(this))
                    ])) : null
                ]) : null, _createElement('div', { 'className': 'MobileDisplay' }, window.Convermax.isLoggedIn ? _createElement('div', {
                    'className': 'card-section card-section--availability card-section--show card-section--show',
                    'key': '6086'
                }, this?.inventory_level > 5 ? _createElement('div', {
                    'className': 'card-stock card-stock--inStock',
                    'key': '6230'
                }, '\n                  ', this?.inventory_level >= 50 ? '50+' : this?.inventory_level, ' In Stock\n                ') : null, 0 < this?.inventory_level && this?.inventory_level <= 5 ? _createElement('span', {
                    'className': 'productView_stock LowStock',
                    'key': '6453'
                }, _createElement('strong', {}, this?.inventory_level, ' In Stock'), _createElement('br', {})) : null, this?.inventory_level == 0 ? _createElement('div', {
                    'className': 'card-stock card-stock--outStock',
                    'key': '6667'
                }, '\n                  Out Of Stock\n                ') : null, _createElement('div', {
                    'className': 'card-qtyAdded',
                    'data-qtyincart': this.id
                })) : null))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products container' }, _createElement('div', { 'className': 'productView-carousel' }, _createElement('h6', { 'className': 'productView-title HPHeading' }, 'RELATED PRODUCTS'), _createElement.apply(this, [
        'div',
        {
            'className': 'cm_related-products-list slick-list cmRepeater_items',
            'data-cm-scroll-container': true
        },
        _map(this.items, repeatItems1.bind(this))
    ])), _createElement('button', {
        'type': 'button',
        'onClick': this.scrollLeft,
        'disabled': this.isLeftBorder,
        'data-cm-scroll-left': true
    }), _createElement('button', {
        'type': 'button',
        'onClick': this.scrollRight,
        'disabled': this.isRightBorder,
        'data-cm-scroll-right': true
    }));
}
        export const componentNames = []