
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableTabRT () {
    return this.vehicleSpecific || this.manyFitments ? _createElement('a', {
        'className': 'tab-title',
        'href': '#tab-fitment',
        'aria-selected': 'false',
        'tabIndex': '0',
        'key': '0'
    }, _createElement('img', { 'src': 'https://cdn11.bigcommerce.com/s-z9vhe3o238/product_images/uploaded_images/guideiconsm2.png' }), 'Buyers Guide') : null;
}
        export const componentNames = []