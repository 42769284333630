
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function MainContentRT () {
    return _createElement('div', { 'id': 'itemsBlock' }, [this.SearchVehicleLabel(function () {
            return window.document.body.classList.contains('cm_search') ? _createElement('div', {
                'className': 'cm_vehicle-widget cm_vehicle-widget__page-title cm_SearchVehicleLabel',
                'key': '0'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, this.vehicleString ? _createElement('h2', {
                'className': 'container-header',
                'key': '194'
            }, _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle), _createElement('span', { 'className': 'cm_vehicle-widget_vehicle' }, '\'', this.vehicleString, '\'')) : null, _createElement('div', { 'className': 'cm_vehicle-widget_label' }, _createElement('p', {}, '\n      Use the "Search For Parts" tool above and confirm all information in a product\'s Fitment Details section\n      to ensure you select the right part for your application\n    ')))) : null;
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            function repeatSize1(size, sizeIndex) {
                return [_createElement('a', {
                        'className': 'cm_page-sizes_size' + (size === this.pageSize ? ' active' : ''),
                        'onClick': () => this.setPageSize(size),
                        'key': '11771'
                    }, '\n        ', size, '\n      ')];
            }
            return _createElement('div', { 'className': 'cm_SearchHeader' }, _createElement('div', { 'className': 'cm_SearchHeader_inner-container' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'button button--primary cm_dialogButton' }, '\n  Filters ', [_createElement('svg', {
                                    'viewBox': '0 0 512 512',
                                    'key': '630'
                                }, _createElement('path', { 'd': 'M488 0H24A24 24 0 0 0 7 41l185 185v206a24 24 0 0 0 10.2 19.7l80 56A24 24 0 0 0 320 488V226L505 41a24 24 0 0 0-17-41z' }))]);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('a', {
                'disabled': true,
                'className': 'button cm_card-compare_link'
            }, '\n    Compare', _createElement('span', { 'className': 'cm_card-compare_count countPill countPill--alt' })), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'Sort by: '), '\n    ', this.sortSelect, '\n  '), _createElement.apply(this, [
                'div',
                { 'className': 'cm_page-sizes' },
                _createElement('span', { 'className': 'cm_page-sizes_title' }, 'Show'),
                _map(this.pageSizes, repeatSize1.bind(this))
            ])));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '7050'
                }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '10350'
                }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1568'
            }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1982'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '2236'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2554'
            }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t have any\n    ',
                !!this.vehicleString ? [
                    ' parts that fit ',
                    _createElement('span', { 'key': '27831' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', {
                    'className': 'search-suggestion',
                    'key': '29871'
                }, _createElement('h3', {
                    'data-uw-rm-heading': 'level',
                    'role': 'heading',
                    'aria-level': '1'
                }, '\n        We couldn\'t find what you were looking for, but it\'s not you, it\'s us.\n      '), _createElement('h5', {
                    'data-uw-rm-heading': 'level',
                    'role': 'heading',
                    'aria-level': '2'
                }, 'Try these suggestions:'), _createElement('ul', {}, _createElement('li', {}, 'Remove spaces from part number searches (Ex. 8C3Z9E527A instead of 8C3Z 9E527-A)'), _createElement('li', {}, '\n          Use an asterisk "*" as a wildcard in your search string if don\'t know the full part number (Ex.\n          18-45*)\n        '), _createElement('li', {}, 'Use our ', _createElement('strong', {}, 'Search For Parts'), ' tool to find parts for your specific application'), _createElement('li', {}, 'Browse our Parts catalog from the main menu link above'), _createElement('li', {}, _createElement('a', {
                    'href': '/contact-us',
                    'data-uw-rm-brl': 'PR',
                    'data-uw-original-href': 'https://www.cardone.com/contact-us'
                }, 'Contact'), '\n          our ASE-Certified technicians for assistance.', _createElement('strong', {}, ' We\'d be happy to help!'))))] : null, this.query || !!this.vehicleString ? [
                '\n    Press ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '40981'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '4491'
            }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '4710'
            }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '4954'
            }, 'Your search request failed to process\n', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '51050' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '51920' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '52750' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '50704' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function repeatSplittedQualifier1(qualifier, qualifierIndex, splittedQualifier, splittedQualifierIndex) {
                            return _createElement('ul', {
                                'className': 'card-text--asm',
                                'key': splittedQualifier
                            }, _createElement('li', {}, splittedQualifier));
                        }
                        function repeatQualifier2(qualifier, qualifierIndex) {
                            return _createElement.apply(this, [
                                'div',
                                {},
                                _map(qualifier.split('\n'), repeatSplittedQualifier1.bind(this, qualifier, qualifierIndex))
                            ]);
                        }
                        function repeatSplittedNote3(note, noteIndex, splittedNote, splittedNoteIndex) {
                            return _createElement('ul', {
                                'className': 'card-text--asm',
                                'key': splittedNote
                            }, _createElement('li', {}, splittedNote));
                        }
                        function repeatNote4(note, noteIndex) {
                            return _createElement.apply(this, [
                                'div',
                                {},
                                _map(note.split('\n'), repeatSplittedNote3.bind(this, note, noteIndex))
                            ]);
                        }
                        function repeatFitment5(fitment, fitmentIndex) {
                            return _createElement('li', { 'key': fitment }, fitment);
                        }
                        function onSubmit6(e) {
                            window.Convermax.addToCart(e);
                        }
                        return _createElement('li', { 'className': 'productCard productCard--list' }, _createElement('article', { 'className': 'card card--cart-enabled' }, _createElement('label', {
                            'className': 'button button--compare',
                            'htmlFor': 'compare-' + this.id
                        }, _createElement('input', {
                            'type': 'checkbox',
                            'name': 'products[]',
                            'id': 'compare-' + this.id,
                            'data-compare-id': this.id,
                            'onClick': window.Convermax.handleProductCompareClick
                        }), '\n      COMPARE\n    '), _createElement('figure', { 'className': 'card-figure' }, _createElement('a', {
                            'className': 'card-figure-link',
                            'href': this.getVehicleSearchUrl(this.custom_url)
                        }, _createElement('div', { 'className': 'card-img-container' }, _createElement('img', {
                            'src': this.imageOrDefault(this.images_image_thumbnail1 || this.image_standard),
                            'alt': this.removeHTML(this.name),
                            'onError': this.onImageError,
                            'className': 'card-image lazyautosizes lazyloaded'
                        }))), _createElement('div', { 'className': 'DesktopDisplay' }, window.Convermax.isLoggedIn ? _createElement('div', {
                            'className': 'card-section card-section--availability card-section--show card-section--show',
                            'key': '773'
                        }, this?.inventory_level > 5 ? _createElement('div', {
                            'className': 'card-stock card-stock--inStock',
                            'key': '911'
                        }, '\n            ', this?.inventory_level >= 50 ? '50+' : this?.inventory_level, ' In Stock\n          ') : null, 0 < this?.inventory_level && this?.inventory_level <= 5 ? _createElement('span', {
                            'className': 'productView_stock LowStock',
                            'key': '1116'
                        }, _createElement('strong', {}, this?.inventory_level, ' In Stock'), _createElement('br', {}), _createElement('span', { 'className': 'SmallText' }, 'Order Now!')) : null, this?.inventory_level == 0 ? _createElement('div', {
                            'className': 'card-stock card-stock--outStock',
                            'key': '1361'
                        }, 'Out Of Stock') : null, _createElement('div', {
                            'className': 'card-qtyAdded',
                            'data-qtyincart': this.id
                        })) : null)), _createElement('div', { 'className': 'card-section card-section--body updated' }, _createElement('div', { 'className': 'ColWrapper' }, _createElement('div', { 'className': 'col1' }, _createElement('p', {
                            'className': 'card-text card-text--brand productView-brand NoMargin',
                            'data-test-info-type': 'brandName'
                        }, _createElement('strong', {}, this.removeHTML(this.brand_name))), _createElement('h4', { 'className': 'card-title NoMargin' }, _createElement('a', Object.assign({}, { 'href': this.getVehicleSearchUrl(this.custom_url) }, { dangerouslySetInnerHTML: { __html: this.name } }))), this.sku ? _createElement('p', {
                            'className': 'card-text card-text--brand',
                            'product-data-sku': this.removeHTML(this.sku),
                            'key': '2067'
                        }, _createElement('strong', {}, 'Part Number:'), ' ', this.removeHTML(this.sku), '\n          ') : null, this.Product_Condition ? _createElement('p', {
                            'className': 'card-text card-text--brand',
                            'key': '2265'
                        }, _createElement('strong', {}, 'Product Condition: '), this.Product_Condition, '\n          ') : null, this.Grade_Type ? _createElement('p', {
                            'className': 'card-text card-text--brand',
                            'key': '2442'
                        }, _createElement('strong', {}, 'Grade Type: '), this.Grade_Type, '\n          ') : null, this.Custom_Type ? _createElement('p', {
                            'className': 'card-text card-text--brand',
                            'key': '2598'
                        }, _createElement('strong', {}, 'Type: '), this.Custom_Type, '\n          ') : null, this.Position ? _createElement('p', {
                            'className': 'card-text card-text--brand',
                            'key': '2750'
                        }, _createElement('strong', {}, 'Position: '), this.Position, '\n          ') : null), _createElement('div', { 'className': 'col2' }, window.Convermax.isLoggedIn && this.Lifecycle_Status === 'Available to Order' ? [
                            this.on_sale ? _createElement('div', {
                                'className': 'price-section WasPrice',
                                'key': '29421'
                            }, _createElement('div', { 'className': 'price-section--label price--sale-label' }, 'Was:'), _createElement('div', { 'className': 'price-section--wrapper' }, _createElement('div', { 'className': 'price-section--withoutTax non-sale-price--withoutTax' }, _createElement('span', {
                                'data-product-non-sale-price-without-tax': true,
                                'className': 'price price--non-sale'
                            }, '\n                    ', this.formatPrice(this.regular_price), '\n                  ')))) : null,
                            _createElement('div', {
                                'className': 'price-section MainPrice',
                                'key': '29423'
                            }, this.on_sale ? _createElement('div', {
                                'className': 'price-section--label',
                                'key': '3651'
                            }, _createElement('span', { 'className': 'price-label' }, ' Price '), _createElement('span', { 'className': 'price-now-label' }, ' Now ')) : null, _createElement('div', { 'className': 'price-section--wrapper' }, _createElement('div', { 'className': 'price-section--withoutTax price-section--primary' }, _createElement('span', {
                                'data-product-price-without-tax': true,
                                'className': 'price price--withoutTax price-primary'
                            }, this.formatPrice(this.price))), _createElement('div', {
                                'id': 'addon-' + this.removeHTML(this.sku),
                                'data-addon': true
                            }), _createElement('div', { 'className': 'price-section CoreChargeText' }, _createElement('div', {
                                'className': 'CoreMessage',
                                'id': 'CoreChargePrice-' + this.removeHTML(this.sku)
                            }), _createElement('div', {
                                'className': 'PDPCoreIcon',
                                'id': 'PDPCoreIcon-' + this.removeHTML(this.sku)
                            })), _createElement('div', { 'className': 'price-section CoreChargeText' }, _createElement('div', {
                                'className': 'FPSMessage',
                                'id': 'FPSPrice-' + this.removeHTML(this.sku)
                            }), _createElement('div', {
                                'className': 'PDPFPSIcon',
                                'id': 'PDPFPSIcon-' + this.removeHTML(this.sku)
                            }))))
                        ] : null)), this.QualifierText?.length || this.Notes?.length || !window.Convermax.isVehicleSelected() && this.fitment_summary?.length ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'card-attributes',
                                'key': '4835'
                            },
                            _map(this.QualifierText, repeatQualifier2.bind(this)),
                            _map(this.Notes, repeatNote4.bind(this)),
                            !window.Convermax.isVehicleSelected() ? _createElement.apply(this, [
                                'ul',
                                {
                                    'className': 'card-text--asm',
                                    'key': '5502'
                                },
                                _map(this.fitment_summary, repeatFitment5.bind(this))
                            ]) : null
                        ]) : null, _createElement('div', { 'className': 'MobileDisplay' }, window.Convermax.isLoggedIn ? _createElement('div', {
                            'className': 'card-section card-section--availability card-section--show card-section--show',
                            'key': '5734'
                        }, this?.inventory_level > 5 ? _createElement('div', {
                            'className': 'card-stock card-stock--inStock',
                            'key': '5872'
                        }, '\n            ', this?.inventory_level >= 50 ? '50+' : this?.inventory_level, ' In Stock\n          ') : null, 0 < this?.inventory_level && this?.inventory_level <= 5 ? _createElement('span', {
                            'className': 'productView_stock LowStock',
                            'key': '6077'
                        }, _createElement('strong', {}, this?.inventory_level, ' In Stock'), _createElement('br', {}), _createElement('span', { 'className': 'SmallText' }, 'Order Now!')) : null, this?.inventory_level == 0 ? _createElement('div', {
                            'className': 'card-stock card-stock--outStock',
                            'key': '6322'
                        }, 'Out Of Stock') : null, _createElement('div', {
                            'className': 'card-qtyAdded',
                            'data-qtyincart': this.id
                        })) : null), _createElement('div', { 'className': 'addToCartPLP ' + (this.out_of_stock ? 'OutOfStockAddToCart' : this.has_options ? 'PLPOptions' : '') }, _createElement('div', { 'className': 'card-section-rightWrapper--inner' }, _createElement('div', { 'className': 'card-section--buttons ' + (!(this.out_of_stock || this.has_options) ? 'card-section--quantity' : '') }, window.Convermax.isLoggedIn && this.Lifecycle_Status === 'Available to Order' ? _createElement('form', {
                            'className': 'form form-cardAdd',
                            'method': 'post',
                            'action': '/cart.php',
                            'encType': 'multipart/form-data',
                            'data-cart-item-add-from-card': true,
                            'atcsku': this.removeHTML(this.sku),
                            'onSubmit': onSubmit6.bind(this),
                            'key': '6873'
                        }, _createElement('div', { 'className': 'add-card-popup' }), _createElement('label', {
                            'className': 'label-cardQuantity ' + (this.out_of_stock ? 'quantity-out-of-stock-text' : ''),
                            'htmlFor': 'qty[]'
                        }, 'Quantity'), !(this.out_of_stock || this.has_options) ? [
                            _createElement('input', {
                                'type': 'hidden',
                                'name': 'action',
                                'value': 'add',
                                'readOnly': true,
                                'key': '74041'
                            }),
                            _createElement('input', {
                                'type': 'hidden',
                                'name': 'product_id',
                                'value': this.id,
                                'readOnly': true,
                                'key': '74043'
                            })
                        ] : null, _createElement('input', {
                            'className': 'form-input form-input-qty ' + (this.out_of_stock ? 'quantity-out-of-stock' : ''),
                            'name': 'qty[]',
                            'type': 'tel',
                            'defaultValue': '1',
                            'data-quantity-min': true,
                            'data-quantity-max': true,
                            'min': '1',
                            'pattern': '[0-9]*',
                            'aria-live': 'polite',
                            'autoComplete': 'off',
                            'disabled': this.out_of_stock || !this.price
                        }), !this.has_options ? _createElement('input', {
                            'data-wait-message': 'Adding\u2026',
                            'data-event-type': 'product-click',
                            'data-product-id': this.id,
                            'className': 'button button--cardAdd ' + (this.out_of_stock ? 'button--cardAction button--out-of-stock' : 'button--primary') + (!this.price ? 'DisabledButton' : ''),
                            'type': 'submit',
                            'value': 'ADD TO CART',
                            'readOnly': true,
                            'disabled': this.out_of_stock || !this.price,
                            'key': '7992'
                        }) : null, this.has_options ? _createElement('a', {
                            'href': this.getVehicleSearchUrl(this.custom_url),
                            'data-event-type': 'product-click',
                            'className': 'button ChooseOptions button--primary button--cardAction',
                            'data-product-id': this.id,
                            'key': '8422'
                        }, 'CHOOSE OPTIONS') : null) : null))))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                'ul',
                { 'className': 'productCards productCards--list cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired.'), '\n  Product information, including pricing and fitments, is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('AggressiveWheels') ? [_createElement('div', { 'key': '4980' }, '\n  Warning: Aggressive wheel options are displayed, but we cannot guarantee they will fit your selected\n  vehicle.\n')] : null, this.hasMessage('IncompatibleParts') && !this.notFilteredByVehicle ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '7050'
                        }, _createElement('div', {}, '\n    The products shown below will not fit a ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    The products results are based on your keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected && !this.notFilteredByVehicle && !this.hasMessage('NonVehicleProducts') ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '10350'
                        }, '\n  The system was unable to find any vehicle specific products that fit a\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Click ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to clear your vehicle\n  selection and browse all products in the category.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1568'
                    }, 'Your request failed to process (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try\nsearching for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1982'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any products. The system searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '2236'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nThe search server could not be reached. Please try again later.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2554'
                    }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                        '\n    This ',
                        this.pageType,
                        ' doesn\'t have any\n    ',
                        !!this.vehicleString ? [
                            ' parts that fit ',
                            _createElement('span', { 'key': '27831' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString ? [' products.'] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', {
                            'className': 'search-suggestion',
                            'key': '29871'
                        }, _createElement('h3', {
                            'data-uw-rm-heading': 'level',
                            'role': 'heading',
                            'aria-level': '1'
                        }, '\n        We couldn\'t find what you were looking for, but it\'s not you, it\'s us.\n      '), _createElement('h5', {
                            'data-uw-rm-heading': 'level',
                            'role': 'heading',
                            'aria-level': '2'
                        }, 'Try these suggestions:'), _createElement('ul', {}, _createElement('li', {}, 'Remove spaces from part number searches (Ex. 8C3Z9E527A instead of 8C3Z 9E527-A)'), _createElement('li', {}, '\n          Use an asterisk "*" as a wildcard in your search string if don\'t know the full part number (Ex.\n          18-45*)\n        '), _createElement('li', {}, 'Use our ', _createElement('strong', {}, 'Search For Parts'), ' tool to find parts for your specific application'), _createElement('li', {}, 'Browse our Parts catalog from the main menu link above'), _createElement('li', {}, _createElement('a', {
                            'href': '/contact-us',
                            'data-uw-rm-brl': 'PR',
                            'data-uw-original-href': 'https://www.cardone.com/contact-us'
                        }, 'Contact'), '\n          our ASE-Certified technicians for assistance.', _createElement('strong', {}, ' We\'d be happy to help!'))))] : null, this.query || !!this.vehicleString ? [
                        '\n    Press ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '40981'
                        }, 'here'),
                        ' to clear\n    ',
                        !this.vehicleString ? [' selection. '] : null,
                        this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '4491'
                    }, 'Your search request timed out.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '4710'
                    }, 'The system was unable to find products to match your entire search of\n', _createElement('span', { 'className': 'query' }, this.originalQuery), '. A partial search was performed instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '4954'
                    }, 'Your search request failed to process\n', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '51050' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '51920' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '52750' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '50704' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })], [this.pagination(function () {
            function repeatButtons1(buttons, buttonsIndex) {
                return [buttons(function () {
                        return this.template === 'prev' ? _createElement('li', { 'className': 'pagination-item pagination-item--previous cmTemplate_prev' }, _createElement('a', {
                            'href': this.pageLink,
                            'className': 'pagination-link pagination-link--previous'
                        }, _createElement('i', {
                            'className': 'icon',
                            'aria-hidden': 'true'
                        }, _createElement('svg', {}, _createElement('use', {
                            'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                            'xlinkHref': '#icon-chevron-left'
                        }))), '\n      Previous\n    ')) : this.template === 'next' ? _createElement('li', { 'className': 'pagination-item pagination-item--next cmTemplate_next' }, _createElement('a', {
                            'href': this.pageLink,
                            'className': 'pagination-link pagination-link--next'
                        }, '\n      Next\n      ', _createElement('i', {
                            'className': 'icon',
                            'aria-hidden': 'true'
                        }, _createElement('svg', {}, _createElement('use', {
                            'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                            'xlinkHref': '#icon-chevron-right'
                        }))))) : this.template === 'page' ? _createElement('li', { 'className': 'pagination-item' + (this.isActive ? ' pagination-item--current' : '') + ' cmTemplate_page' }, _createElement('a', {
                            'href': this.pageLink,
                            'className': 'pagination-link'
                        }, this.page)) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'pagination cm_pagination' }, _createElement.apply(this, [
                'ul',
                { 'className': 'pagination-list cmRepeater_buttons' },
                _map(this.buttons, repeatButtons1.bind(this))
            ]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:SearchVehicleLabel","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:message","cm:message","cm:SearchResult","cm:pagination"]