
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function collapsibleVerifyFitmentRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                    var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                    var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                    var isPopularField = popularEntries.length && unpopularEntries.length;
                    return [
                        !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                            'value': '',
                            'key': 'null-option'
                        }, '\n          ', this.title, '\n        ') : null,
                        this.loading ? _createElement('option', {
                            'key': 'loading-option',
                            'disabled': true
                        }, '...loading...') : null,
                        isPopularField ? [
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '10541'
                            }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                            _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '10544'
                            }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                        ] : null,
                        _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                    ];
                }
                function scopeShowSelectedValues4() {
                    var showSelectedValues = this.selectedEntries.length;
                    return _createElement('option', {
                        'key': '_current',
                        'value': '_current'
                    }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                }
                function repeatEntry5(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3378'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3580'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'aria-label': this.title
                }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'aria-label': this.title,
                        'key': '2688'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    function repeatSplittedValue2(verifyFitmentInfo, key, keyIndex, value, valueIndex, splittedValue, splittedValueIndex) {
        return [_createElement('li', { 'key': '86161' }, splittedValue)];
    }
    function repeatValue3(verifyFitmentInfo, key, keyIndex, value, valueIndex) {
        return [_map(value.split('\n'), repeatSplittedValue2.bind(this, verifyFitmentInfo, key, keyIndex, value, valueIndex))];
    }
    function repeatKey4(verifyFitmentInfo, key, keyIndex) {
        return [_map(verifyFitmentInfo[key], repeatValue3.bind(this, verifyFitmentInfo, key, keyIndex))];
    }
    function scopeVerifyFitmentInfo5() {
        var verifyFitmentInfo = this.productData?.verify_fitment_info;
        return [verifyFitmentInfo && Object.keys(verifyFitmentInfo).length > 0 ? _createElement('div', {
                'className': 'cm_verify-fitment_vehicle-info__container',
                'key': '80811'
            }, _createElement('h5', { 'className': 'GreyHeading' }, 'Fitment Details'), _createElement.apply(this, [
                'ul',
                { 'className': 'cm_verify-fitment_vehicle-info__values' },
                _map(Object.keys(verifyFitmentInfo), repeatKey4.bind(this, verifyFitmentInfo))
            ])) : null];
    }
    function repeatSelects6(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                    var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                    var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                    var isPopularField = popularEntries.length && unpopularEntries.length;
                    return [
                        !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                            'value': '',
                            'key': 'null-option'
                        }, '\n          ', this.title, '\n        ') : null,
                        this.loading ? _createElement('option', {
                            'key': 'loading-option',
                            'disabled': true
                        }, '...loading...') : null,
                        isPopularField ? [
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '10541'
                            }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                            _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                            _createElement('option', {
                                'className': 'cm_option_title',
                                'disabled': true,
                                'key': '10544'
                            }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                        ] : null,
                        _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                    ];
                }
                function scopeShowSelectedValues4() {
                    var showSelectedValues = this.selectedEntries.length;
                    return _createElement('option', {
                        'key': '_current',
                        'value': '_current'
                    }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [this.title] : null);
                }
                function repeatEntry5(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3378'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3580'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'aria-label': this.title
                }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'aria-label': this.title,
                        'key': '2688'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_verify-fitment cm_vehicle-widget__verify-fitment' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '86'
    }, !this.aboutToChange ? [
        _createElement('h5', {
            'className': 'cm_verify-fitment_notification',
            'key': '1951'
        }, '\n        Complete \'Check It Fits\' to verify this part for your application\n      '),
        _createElement('div', {
            'className': 'cm_verify-fitment_collapsble-button button button--primary',
            'onClick': this.toggleCollapsed,
            'key': '1953'
        }, '\n        Check It Fits\n      ')
    ] : null, _createElement('div', { 'className': 'cm_collapsible-elem' }, _createElement('div', { 'className': 'cm_vehicle-widget_body' }, [
        _createElement.apply(this, [
            'div',
            {
                'className': 'cm_vehicle-widget_select-container  cmRepeater_selects',
                'key': '6360'
            },
            _map(this.selects, repeatSelects1.bind(this))
        ]),
        _createElement('div', {
            'className': 'cm_vehicle-widget_button-container',
            'key': '6362'
        }, _createElement('div', {
            'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary',
            'onClick': this.selectVehicle,
            'disabled': !this.allowToSetVehicle,
            'role': 'button',
            'tabIndex': this.allowToSetVehicle ? '0' : '-1'
        }, '\n    FIND PARTS\n  '), _createElement('div', {
            'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button',
            'onClick': this.discardVehicle,
            'disabled': !this.allowToDiscardVehicle,
            'role': 'button',
            'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
        }, '\n    Clear\n  '))
    ]))) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_verify-fitment__' + this.fits + ' cmTemplate_locked',
        'key': '7176'
    }, _createElement('h5', { 'className': 'cm_verify-fitment_title' }, this.fits === 'yes' ? _createElement('span', {
        'className': 'cm_verify-fitment_fits',
        'key': '7331'
    }, 'Part Fits your') : null, this.fits === 'no' ? _createElement('span', {
        'className': 'cm_verify-fitment_doesnotfit',
        'key': '7434'
    }, ' Part does not fit your ') : null, _createElement('span', { 'className': 'cm_verify-fitment_vehicle' }, this.vehicleString || 'Vehicle')), _createElement('div', { 'className': 'cm_verify-fitment_buttons' }, _createElement('a', {
        'className': 'cm_verify-fitment_change',
        'onClick': this.changeVehicle
    }, 'Change your vehicle'), this.fits === 'no' ? _createElement('div', {
        'className': 'cm_verify-fitment_compatible-parts-container',
        'key': '7800'
    }, _createElement('a', {
        'href': this.getVehicleUrl(),
        'className': 'cm_verify-fitment_compatible-parts'
    }, '\n          Click here for products that do fit your vehicle\n        ')) : null), this.fits === 'yes' ? scopeVerifyFitmentInfo5.apply(this, []) : null) : null, this.template === 'inexact' ? _createElement('div', {
        'className': 'cm_vehicle-widget_specifier cmTemplate_inexact',
        'key': '8861'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, 'Please specify ', this.fieldsToSpecify.join(', '), ' for your ', this.vehicleString), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects6.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button button button--primary button button--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    FIND PARTS\n  ')))) : null);
}
        export const componentNames = ["cm:filterInput","cm:filterInput"]