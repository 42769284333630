import { SearchPage } from 'Components'
import TemplateMainContent from 'Stores/cardone/Templates/MainContent.rt'
import { VehicleWidget } from 'Components'
import { SearchHeader } from 'Components'
import { FacetPanel } from 'Components'
import TemplateFacetPanelContainer from 'Stores/_default-store/Templates/FacetPanelContainer.rt'
import { SearchResult } from 'Components'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { RequestPanel } from 'Components'
import TemplaterequestPanelrequestPanel from 'Stores/cardone/Templates/requestPanel/requestPanel.rt'
import { VerifyFitment } from 'Components'
import TemplatefitmentSearchcollapsibleVerifyFitment from 'Stores/cardone/Templates/fitmentSearch/collapsibleVerifyFitment.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTableTab from 'Stores/cardone/Templates/fitmentSearch/fitmentTableTab.rt'
import TemplatefitmentSearchfitmentTable from 'Stores/_default-store/Templates/fitmentSearch/fitmentTable.rt'
import { RelatedItems } from 'Components'
import TemplateproductrelatedItems from 'Stores/cardone/Templates/product/relatedItems.rt'
import { ProductData } from 'Components'
import TemplateproductoeNumbers from 'Stores/cardone/Templates/product/oeNumbers.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'

export const compMap = {
  SearchPage,
VehicleWidget,
SearchHeader,
FacetPanel,
SearchResult,
SearchBoxDialogButton,
RequestPanel,
VerifyFitment,
FitmentTable,
RelatedItems,
ProductData,
FacetDialog,
SearchBoxDialog
} 
export const tplMap = {
  'MainContent': TemplateMainContent,
'FacetPanelContainer': TemplateFacetPanelContainer,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'requestPanel/requestPanel': TemplaterequestPanelrequestPanel,
'fitmentSearch/collapsibleVerifyFitment': TemplatefitmentSearchcollapsibleVerifyFitment,
'fitmentSearch/fitmentTableTab': TemplatefitmentSearchfitmentTableTab,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'product/relatedItems': TemplateproductrelatedItems,
'product/oeNumbers': TemplateproductoeNumbers,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog
};