import { bigcommerceAppClientId } from '../_common/constants.js';

const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = ['EngineLiter'];
const requestPanelFields = [...baseFitmentFields, ...extraFitmentFields, 'Part Type'];

const isBrandPLP = !!window.document.querySelector('.brand.category .page-content');

window.Convermax.isLoggedIn = window.document.querySelector(
  '.navUser .navUser-action[href="/account.php"].has-dropdown',
);

window.Convermax.showCardFitments = (e) => {
  const cardButton = e.target;

  if (cardButton) {
    const cardFitments = cardButton.nextSibling;

    if (cardFitments) {
      cardFitments.classList.toggle('cm_hide');

      if (cardFitments.classList.contains('cm_hide')) {
        cardButton.innerText = 'Show fitments';
      } else {
        cardButton.innerText = 'Hide fitments';
      }
    }
  }
};

window.Convermax.handleProductCompareClick = () => {
  const productsIdsToCompare = [
    ...window.document.querySelectorAll('.button--compare input[name="products[]"]:checked'),
  ].map((product) => product.dataset.compareId);

  const compareBtn = window.document.body.querySelector('.cm_card-compare_link');
  const compareCount = window.document.body.querySelector('.cm_card-compare_count');

  if (!compareBtn) {
    return;
  }

  if (productsIdsToCompare.length === 0) {
    compareBtn.removeAttribute('href');
    compareCount.style.display = 'none';
    compareBtn.setAttribute('disabled', true);
  } else {
    compareCount.textContent = productsIdsToCompare.length;
    compareCount.style.display = 'block';
    compareBtn.removeAttribute('disabled');
    compareBtn.classList.toggle('show', productsIdsToCompare.length);
  }

  if (productsIdsToCompare.length === 1) {
    compareBtn.removeAttribute('href');
  }

  if (productsIdsToCompare.length > 1) {
    compareBtn.href = `/compare/${productsIdsToCompare.join('/')}${productsIdsToCompare.length ? '/' : ''}`;
  }
};
window.Convermax.addToCart = (e) => {
  const addToCartForm = e.target.closest('.form-cardAdd');
  const addToCartFormData = new FormData(addToCartForm);
  const addToCartBtn = addToCartForm.querySelector('.button.button--primary.button--cardAdd');
  const originalBtnVal = addToCartBtn.value;
  addToCartBtn.value = 'Adding…';

  e.preventDefault();

  const request = new XMLHttpRequest();
  request.open('POST', addToCartForm.getAttribute('action'));
  request.send(addToCartFormData);
  request.onreadystatechange = () => {
    addToCartBtn.value = originalBtnVal;

    if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
      window.location.reload();
    }
  };
};

const customerGroupId = getGroupId();
async function getGroupId() {
  if (!window.Convermax?.isLoggedIn) {
    return '';
  }
  const groupId = await new Promise((resolve) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4) {
        if (xmlHttp.status === 200) {
          const jwt = xmlHttp.responseText;
          const [, jsonPart] = jwt.split('.');
          const { customer } = JSON.parse(atob(jsonPart));
          resolve(customer.group_id);
        } else if (xmlHttp.status === 404) {
          resolve(null);
        } else {
          resolve(null);
        }
      }
    };
    xmlHttp.open('GET', `/customer/current.jwt?app_client_id=${bigcommerceAppClientId}`, true);
    xmlHttp.send();
  });
  return groupId || '';
}

function getActiveCurrency() {
  return document.querySelector(`[data-dropdown=currencySelection]`)?.textContent.trim().toLowerCase();
}

function getSelection(itemData) {
  const value = itemData.Part_Type;
  const field = 'Part Type';
  return !value ? [] : Array.isArray(value) ? value.map((term) => ({ field, term })) : { field, term: value };
}

export default {
  platform: 'bigcommerce',
  searchPageUrl: '/search.php',
  SearchRequestDefaults: {
    pageSize: 12,
    sort: 'name',
    extra: {
      customerGroupId,
      currency: getActiveCurrency(),
    },
  },
  autocomplete: {
    requestDefaults: {
      extra: {
        customerGroupId,
        currency: getActiveCurrency(),
      },
    },
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    doNotSaveSelectedVehicle: true, // localStorage in this store is cleared when entering a new page
    isAutoVehicleSelectionDisabled: true,
    isExtraFieldsAlwaysVisible: true,
    getFitmentSearchTitle: () => (window.location.pathname.includes('/search/') ? 'Search results for ' : ''),
  },
  page: {
    getPageType: (defaults) =>
      isBrandPLP
        ? 'brand'
        : window.document.querySelector('.body--product-page')
          ? 'product'
          : defaults.getter(),
  },
  pagination: {
    edgeRange: 0,
    centralRange: 3,
    disableDelimeters: true,
  },
  product: {
    getItemId: () => window.Convermax.productId,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: {
        selector: '.product-listing-container.SearchPageContainer',
        class: 'cm_results cm_main-content',
      },
      template: 'MainContent',
    },
    {
      name: 'SearchVehicleLabel',
      type: 'VehicleWidget',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'date_created:desc': 'Newest Items',
        'total_sold:desc': 'Best Selling',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'review_score:desc': 'By Review',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
        'relevance': 'Relevance',
      },
      pageSizes: [12, 24, 36, 48, 96],
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: {
        selector: '.body .category .productCategoryCompare',
        class: 'cm_main-content cm_main-content__category-page',
      },
      template: 'MainContent',
    },
    {
      name: 'FacetPanel',
      location: '.body .category aside.page-sidebar',
      template: 'FacetPanelContainer',
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { replace: '#quickSearch form.form', class: 'form' },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'RequestPanel',
      location: { selector: '#menu #ymm', class: 'cm_vehicle-widget__header-container' },
      template: 'requestPanel/requestPanel',
      needInitRequest: true,
      initCollapsed: true,
      isAutoRedirectDisabled: true,
      selectFields: requestPanelFields,
      shouldBeIsolated: () => true,
    },
    {
      name: 'VerifyFitment',
      location: '#cm-verify-fitment',
      template: 'fitmentSearch/collapsibleVerifyFitment',
      isAlwaysColumnLayout: true,
      initCollapsed: true,
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: {
        replace: '.tab.tab-fitment',
        class: 'tab',
        wrapper: 'li',
      },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: '#tab-fitment',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'RelatedItems',
      location: {
        replace: '#related-items',
        class: 'PDPBelowProducts',
      },
      template: 'product/relatedItems',
      count: 11,
      getSelection,
    },
    {
      name: 'ProductData',
      location: {
        replace: '.productView-table.oe-numbers',
        class: 'productView-table oe-numbers',
      },
      template: 'product/oeNumbers',
      fields: ['oe_numbers'],
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};
